import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import picture from "../../assets/profileIcon.png";

const Footer = () => {
	return (
		<footer id="footer">
			<div className="footer__container">
				<div className="footer__main">
					<div className="footer__title">
						<img src={picture} alt="Bayu Nugraha" />
						<h1>Let's work together!</h1>
					</div>
					<div className="footer__button">
						<Link to="/me">Get in touch</Link>
					</div>
					<div className="footer__contact">
						<a href="mailto:r.bayunugraha.s@gmail.com">G-Mail</a>
						<a
							target="_blank"
							rel="noreferrer noopener"
							href="https://wa.me/6285157778195/?text=Hello%20Bayu"
						>
							Whatsapp
						</a>
					</div>
				</div>
				<div className="footer__info">
					<div className="footer__section">
						<a
							target="_blank"
							rel="noreferrer noopener"
							href="https://instagram.com/r.bayunugraha"
						>
							Instagram
						</a>
						<a target="_blank" rel="noreferrer noopener" href="https://youtube.com/">
							YouTube
						</a>
						<a target="_blank" rel="noreferrer noopener" href="https://github.com/bayyNGRH">
							GitHub
						</a>
					</div>
					<div className="footer__section">
						<a target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/in/">
							LinkedIn
						</a>
						<a target="_blank" rel="noreferrer noopener" href="https://www.tiktok.com/">
							TikTok
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
