import React from "react";
import { Link } from "react-router-dom";
import "./About.css";

const About = () => {
	return (
		<section className="about" id="about">
			<div className="about__container">
				<div className="about__typography">
					<span>about me</span>
					<span>about me</span>
					<span>about me</span>
				</div>
				<div className="about__text">
					<span className="about__description">
						Hello! I am Bayu Nugraha, I've passion in
						<span className="about__outline"> Web Development</span> both{" "}
						<span className="about__outline">user interface</span> and database{" "}
						<span className="about__outline">management</span> system.
					</span>
					<span className="about__button">
						<Link to="/me">
							Read More About Me <i className="uil uil-arrow-up-right"></i>
						</Link>
					</span>
				</div>
			</div>
		</section>
	);
};

export default About;
