import React from "react";
// import { Link } from "react-router-dom";

import LoaderMe from "../components/LoaderMe/LoaderMe";
import MoreInfo from "../components/moreInfo/MoreInfo";
import Contact from "../components/contact/Contact";

const Me = () => {
	return (
		<section>
			<LoaderMe />
			<MoreInfo />
			<Contact />
		</section>
	);
};

export default Me;
